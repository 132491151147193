//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from "~/mixins/breakpointsList.mixin";
import logo from '~/assets/img/adidas-logo-white.svg';
import {mapGetters} from "vuex";

export default {
    name: "SmartAppBanner",
    data() {
        return {
            logo: logo,
            baseLink: 'https://adidas-ua.onelink.me/Wc07?af_xp=custom&pid=Smart-banner&deep_link_value=',
            closeApp: true,
            hidePaths: ['/cart','/account', '/account/profile', "/account/preferences", '/account/addresses', '/account/history', '/time-out']
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    computed: {
        ...mapGetters({
            pageType: "dynamicPage/pageType",
        }),
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else  {
                return false;
            }
        },
        routeInfo() {
            return this.$route
        },
        isMobile() {
            return this.mobileDown;
        },
        isHidePaths() {
            return this.hidePaths.includes(this.routeInfo.path)
        },
        isUnknownPage() {
            return this.pageType === "unknown"
        },
        fullLink() {
            return `${this.baseLink}https://www.adidas.ua${this.routeInfo.fullPath}`
        },
        isSafari() {
            return navigator.vendor && navigator.vendor.indexOf("Apple") > -1 && navigator.userAgent && navigator.userAgent.indexOf("CriOS") == -1 && navigator.userAgent.indexOf("FxiOS") == -1;
        },
        navigator() {
            return navigator.userAgent || navigator.vendor || window.opera || false;
        },
        isInstagramBrowser() {
            return this.navigator && (this.navigator.indexOf('Instagram') > -1);
        },
        isFbBrowser() {
            return this.navigator && ((this.navigator.indexOf("FBAN") > -1) || (this.navigator.indexOf("FBAV") > -1));
        },
        isIOSShown() {
            return !this.isSafari || this.isInstagramBrowser || this.isFbBrowser;
        },
        showSAP() {
            return this.isMobile && !this.closeApp && !this.isHidePaths && !this.isUnknownPage && this.isIOSShown;
        },
    },
    methods: {
        closeSAP() {
            this.closeApp = true;
            sessionStorage.setItem("hideSap", "true");
        }
    },
    mounted() {
        let hideSap = sessionStorage.getItem("hideSap");
        this.closeApp = hideSap === 'true'
    },
}
